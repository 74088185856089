// REMOVEME: Remove this file after we go live with Neon content
import { useFeatureFlags } from './useFeatureFlags'
import { PRICE_CHANGE_ALERT } from '../constants'

/**
 * Returns a boolean value indicating whether neon price is changing
 *
 * @returns {Boolean} usePriceChangeAlertFlag
 */
const usePriceChangeAlertFlag = () => {
  const result = (useFeatureFlags([PRICE_CHANGE_ALERT], false)).PRICE_CHANGE_ALERT
  return result
}

export { usePriceChangeAlertFlag }
