import { format, utcToZonedTime } from 'date-fns-tz'

/**
 * Format iso string to local date
 * @param isoTimeString
 * @param pattern
 * @param timeZone
 * @returns {string} empty string if it's not valid iso string
 */
export const formatTime = (isoTimeString, pattern = 'd MMM yyyy', timeZone = 'Pacific/Auckland') => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(isoTimeString)) return ''
  const convertByUTC = utcToZonedTime(isoTimeString, timeZone)
  return format(convertByUTC, 'd MMM yyyy', { timeZone })
}
